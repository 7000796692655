import { ColorShade } from '../interface/color-shade';

export function lightGenerator(color: string): ColorShade {
  return {
    name: `${color}-light`,
    editor: `editor editor-${color}-light`,
    background: `bg-${color}-0`,
    spacing: 'py-10 md:py-20',
    color: {
      badgeBackground: `bg-${color}-100`,
      cardBackground: `bg-${color}-100`,
      heading: `text-${color}-1000`,
      headingSecondary: `text-${color}-700`,
      text: `text-${color}-900`,
      textHover: `hover:text-${color}-1000`,
      badgeText: `text-${color}-950`,
      timeline: `after:bg-${color}-700 before:bg-${color}-700`,
      timelineText: `text-${color}-900`,
      cta: `text-${color}-900`,
      divide: `divide-${color}-1000`,
      inputBorder: `border-${color}-200`,
    },
    button: {
      primary: {
        bgDefault: `bg-${color}-700`,
        bgHover: `group-hover:bg-${color}-800`,
        bgActive: `active:bg-${color}-800`,
        textDefault: `text-${color}-0`,
        outlineDefault: `border-${color}-700`,
        outlineHover: `border-${color}-800`,
        outlineHoverBackground: `hover:bg-${color}-50`,
        outlineActiveBackground: `active:bg-${color}-50`,
        outlineActive: `border-${color}-800`,
        textOutline: `text-${color}-700`,
      },
      secondary: {
        bgDefault: `bg-${color}-900`,
        bgHover: `group-hover:bg-${color}-950`,
        bgActive: `active:bg-${color}-950`,
        textDefault: `text-${color}-0`,
        outlineDefault: `border-${color}-900`,
        outlineHover: `hover:border-${color}-950`,
        outlineHoverBackground: `hover:bg-${color}-950`,
        outlineActiveBackground: `active:bg-${color}-900`,
        outlineHoverText: `hover:text-${color}-0`,
        outlineActive: `border-${color}-950`,
        textOutline: `text-${color}-900`,
      },
      textLink: {
        textColor: `text-${color}-900`,
      }
    },
    input: {
      placeholderColor: `placeholder-${color}-900`,
      border: `border-${color}-900`,
    },
  }
}
